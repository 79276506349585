import React from "react";
import Style from "./SubscriptionText.module.scss";
import useSubscriptionText from "./useSubscriptionText";
import { useLocation } from "react-router-dom";

const SubscriptionText = ({ getTextById }) => {
  const { t, basicData } = useSubscriptionText();
  const location = useLocation();
  const currentPath = location.pathname;
  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";

  return (
    <div className={`${Style.subscription} ${rtl}`}>
      <h2 className={Style.title}>{getTextById(1)}</h2>
      <h2 className={`h2 ${Style.subtitle}`}>{getTextById(2)}</h2>
      <p>{getTextById(3)}</p>
      <ul>
        <li>{getTextById(4)}</li>
        <li>{getTextById(5)}</li>
      </ul>

      <h2 className={`h2 ${Style.sm_title}`}>
        {getTextById(45)} {parseInt(basicData?.data?.amount)}/
        {getTextById(42).toUpperCase()}
      </h2>
    </div>
  );
};

export default SubscriptionText;

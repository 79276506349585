import React from "react";
import Style from "./logout.module.scss";
import useLogout from "./useLogout";
export const Logout = () => {
  const { token, handleLogout, data } = useLogout();
  return (
    <>
      {token && (
        <div className={Style.wrapper}>
          <button
            className={`${Style?.logout_btn} mt-3`}
            onClick={handleLogout}
          >
            <span>
              {data?.data?.user_name} <img src="/assets/logout.svg" alt=""></img>
            </span>
          </button>
        </div>
      )}
    </>
  );
};

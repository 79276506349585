import { destroyCookie, parseCookies } from "nookies";
import { logout } from "./api";
import { useNavigate } from "react-router-dom";
import { useSwrStatic } from "../../helpers/swr";
const useLogout = () => {
  const token = parseCookies()?.USER_ACCESS_TOKEN;
  const navigate = useNavigate();
  const { data, mutate } = useSwrStatic(`/user-details`);
  const handleLogout = () => {
    const lang = localStorage.getItem("language_type");
    logout()
      .then((d) => {
        destroyCookie(null, "USER_ACCESS_TOKEN", { path: "/" });
        navigate(`/${lang}`);
        localStorage.clear();
        localStorage.setItem("language_type", lang);
      })
      .catch((err) => {
        console.log("failed to logout");
      });
  };
  return { token, handleLogout, data, mutate };
};

export default useLogout;

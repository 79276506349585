import Style from "./Home.module.scss";
import LoginForm from "../LoginForm";
import SubscriptionText from "../SubscriptionText";
import TermsandConditions from "../TermsandConditions";
import useHome from "./useHome";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../context";

const Home = () => {
  useHome();
  const location = useLocation();
  const currentPath = location.pathname;
  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";
  const { getTextById, isLoading } = useContext(GlobalContext);

  return (
    <>
      <section className={`${Style.delivery_subscription} ${rtl}`}>
        <div className="section_wrap">
          <div className="inner">
            <div className="row">
              <div className={`col-lg-6`}>
                <SubscriptionText getTextById={getTextById} />
                <div className={`${Style.desktop}`}>
                  <LoginForm getTextById={getTextById} />
                </div>
              </div>
              <div className="col-lg-6 ">
                <figure>
                  <img
                    src="/assets/images/subscription-poster.png"
                    alt="poster"
                  />
                </figure>
                <div className={Style.mobile}>
                  <LoginForm getTextById={getTextById} />
                </div>
              </div>
            </div>
            <TermsandConditions getTextById={getTextById} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
